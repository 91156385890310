import React from 'react'
import { useTranslation } from 'react-i18next'
import two_persons from '../../../../assets/2-persons.svg'
import invite_icon from '../../../../assets/red-mail.png'
const CallToAction = () => {

  const { t } = useTranslation();

  return (
    <div className="invite-rect">
      <img src={two_persons} alt="two persons" className="two-persons" />
      <div className="calltoaction-rect-subtitle">
        <div>
          {t("Vous avez envie de vivre de meilleures relations avec votre entourage personnel et profitez de plus de calme dans votre vie ? Vivez alors l’expérience avec Be Well Together. ")}
        </div>
        <div>
          {t("Developed by an expert clinical psychologist in emotions, BWT uses advanced data analysis to explore and understand the unique relational dynamics in various life contexts. Be Well Together (BWT) is a revolutionary initiative dedicated to enriching all dimensions of existence—work, family, love, friendship, sports, and education. Whether it’s strengthening a sports team, enhancing family bonds, promoting engagement in a classroom, enriching romantic relationships, or optimizing cohesion within a sports team, BWT is the key to more unified and productive environments where everyone finds their place and feels understood and appreciated. ")}
        </div>
        <div>
          {t("Contact us to be part of a pilot project")}
        </div>
      </div>
      <div className="calltoaction-results-invite-frame cursor-pointer wwt-btn" onClick={() => window.location = 'mailto:info@bewelltogether.co'}>
        <img src={invite_icon} alt="invite-icon" className="results-invite-icon" />
        <div className="share-results-invite-content">info@bewelltogether.co</div>
      </div>
    </div>
  )
}

export default CallToAction
import server from '@config';
import { getIndividualResult, getProfile } from "api/api";
import axios from 'axios';
import LegalModal from "components/Home/Modals/LegalModal";
import Loader from "components/Loader/Loader";
import { useAuth } from 'context/auth';
import { ProfileContext } from "context/profile";
import { Formik } from "formik";
// import { Formik } from "formik";
// import * as Yup from "yup";
import {
  MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBInput, MDBModal, MDBRow
} from "mdbreact";
import React, { useContext, useEffect, useState } from "react";
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { withRouter } from "react-router";
import * as Yup from 'yup';
import FacebookButton from "./FacebookButton";
import GoogleButton from "./GoogleButton";
import LinkedinButton from "./LinkedinButton";
import "./LoginModal.css";
// import { store } from 'react-notifications-component';

function LoginModal(props) {
  const [modal, setModal] = useState(false);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [signUpForm, setSignUpForm] = useState(false);
  const [btnClass, setBtnClass] = useState("wwt-btn wwt-btn-pink font-montserrat");
  const [loading, setLoading] = useState(false);
  const [signUpEnabled, setSignUpEnabled] = useState(true);
  const { t } = useTranslation();
  const { setAuthTokens } = useAuth()
  let params = new URLSearchParams(window.location.search)
  let ref = params.get('ref')

  const profileContext = useContext(ProfileContext);

  useEffect(() => {
    if (profileContext.profile) {
      getIndividualResult();
    } else {
      if (props.showLoginDefault || (props.openDefault && params.get('questionnaire_id')) || (props.openDefault && params.get('mission'))
        || (props.openDefault && params.get('contact'))) {
        setModal(true);
      }
    }
  }, [profileContext])

  useEffect(() => {
    if ([true, false].includes(props.signUpEnabled)) {
      setSignUpEnabled(props.signUpEnabled);
    }
  }, [props.signUpEnabled])

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    if (params.get("code")) {
      setModal(true);
    }
    if (props.pageNum === 1) {
      setBtnClass("wwt-btn wwt-btn-pink font-montserrat");
    }
  }, [props.pageNum]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleForgotPasswordModal = () => {
    setForgotPasswordModal(!forgotPasswordModal);
  };

  const toggleSignUpForm = () => {
    setSignUpForm(!signUpForm);
  }

  const signIn = (values) => {
    console.log('sign in user values', values);
    let data = {
      email: values.email,
      password: values.password,
      referral: ref,
    }
    const mission = new URLSearchParams(window.location.search).get('mission')
    if (mission) {
      data.mission = mission;
    }
    const contact = new URLSearchParams(window.location.search).get('contact')
    if (contact) {
      data.contact = contact;
    }
    const company = new URLSearchParams(window.location.search).get('company')
    if (company) {
      data.company = company;
    }
    if (props.location.pathname.includes('/pro')) {
      data.is_b2b = true;
    } else {
      data.is_b2b = false;
    }
    let config = {
      method: "post",
      url: server + '/auth/user/login',
      data: data
    }
    console.log(config)
    setLoading(true);
    axios(config)
      .then(res => {
        console.log('login response', res);
        setLoading(false);
        const data = res.data;
        if (data.status) {
          localStorage.setItem('email', data.email)
          localStorage.setItem('referral', data.referral)
          localStorage.setItem('token', data.token)
          setAuthTokens(data.token)
          toast.success(t("You logged in successfully"));
          // gaEvent("CONNECTION", "CONNECTION_LINKEDIN", "Connected with LinkedIn");
          getProfile().then((profile) => {
            profileContext.setProfile();
            if (profile) {
              profileContext.setProfile();
              getIndividualResult().then((res) => {
                if (res.data.w_1 !== -1) {
                  props.setPassed(true)
                }
                props.setEmail(localStorage.getItem('email'))
              })
            }
          })
        } else {
          toast.error(data.message);
        }
      })
      .catch((err) => {
        console.log(err)
        setLoading(false);
        if (err && err.response && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      })
  }


  const signUp = (values) => {
    console.log('sign up user values', values);
    let data = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      password: values.password,
      referral: ref,
    }
    const mission = new URLSearchParams(window.location.search).get('mission')
    if (mission) {
      data.mission = mission;
    }
    const contact = new URLSearchParams(window.location.search).get('contact')
    if (contact) {
      data.contact = contact;
    }
    const company = new URLSearchParams(window.location.search).get('company')
    if (company) {
      data.company = company;
    }
    let config = {
      method: "post",
      url: server + '/auth/user/register',
      data: data
    }
    console.log(config)
    setLoading(true);
    axios(config)
      .then(res => {
        console.log('login response', res);
        const data = res.data;
        setLoading(false);
        if (data.status) {
          localStorage.setItem('email', data.email)
          localStorage.setItem('referral', data.referral)
          localStorage.setItem('token', data.token)
          setAuthTokens(data.token)
          // gaEvent("CONNECTION", "CONNECTION_LINKEDIN", "Connected with LinkedIn");
          getProfile().then((profile) => {
            profileContext.setProfile();
            if (profile) {
              profileContext.setProfile();
              getIndividualResult().then((res) => {
                if (res.data.w_1 !== -1) {
                  props.setPassed(true)
                }
                props.setEmail(localStorage.getItem('email'))
              })
            }
          })
        } else {
          toast.error(data.message);
        }
      })
      .catch(err => {
        console.log(err)
        setLoading(false);
        if (err && err.response && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      })
  }

  const sendResetPasswordEmail = (values) => {
    console.log('send create password email', values);
    let data = {
      email: values.email
    }
    let config = {
      method: "post",
      url: server + '/password_reset/',
      data: data
    }
    console.log(config)
    setLoading(true);
    axios(config)
      .then(res => {
        console.log('login response', res);
        setLoading(false);
        setTimeout(() => {
          if (forgotPasswordModal) {
            toggleForgotPasswordModal();
          }
        }, 500);
      })
      .catch((err) => {
        console.log(err)
        setLoading(false);
        if (err && err.response && err.response.data.email && err.response.data.email.length) {
          toast.error(err.response.data.email[0]);
        }
      })
  }

  const getIndividualResult1 = (email) => {
    let data1 = { 'email': email }
    let config = {
      method: 'post',
      url: server + '/result/individual_result/',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data1
    }
    setLoading(true);
    axios(config)
      .then(res => {
        setLoading(false);
        if (res.data.w_1 !== -1) {
          props.setPassed(true)
        }
        props.setEmail(email)
      })
      .catch((err) => {
        setLoading(false);
      })
  }

  return (
    <MDBContainer id='login-modal-container' className={props.showLoginDefault ? "" : "container login-modal-container w-auto row justify-content-center mb-4 mb-md-0 mr-0 mr-md-4"}>
      {props.btnText && !props.showLoginDefault &&
        <div className={btnClass} onClick={toggle}>
          {t(props.btnText)}
        </div>
      }
      <MDBModal isOpen={modal} toggle={toggle} className="login-modal">
        <Loader loading={loading}>
          <MDBRow>
            <MDBCol>
              <MDBCard>
                <MDBCardBody>
                  <div className="text-left">
                    <div className="login-title">
                      {t("Log in/Sign up")}
                    </div>
                    <div className="login-modal-explanation">
                      {t("If you have received a link to complete the questionnaire, you must create an account so that your relationer, or your business contact, can access the results, and you can view a brief summary of them. ")}
                    </div>
                  </div>

                  <LinkedinButton
                    setEmail={props.setEmail}
                    setPassed={props.setPassed}
                    setModal={setModal}
                  />
                  <div className="social-login-button-group">
                    <FacebookButton
                      setEmail={props.setEmail}
                      setPassed={props.setPassed}
                      setModal={setModal}
                    />
                    <GoogleButton
                      setEmail={props.setEmail}
                      setPassed={props.setPassed}
                      setModal={setModal}
                    />
                  </div>
                  <div className='social-login-header'>
                    <span className='px-4 white'>{t("or")}</span>
                  </div>
                  {signUpForm ?
                    <Formik
                      key='signUpForm'
                      initialValues={{ email: "", password: "", firstName: '', lastName: '' }}
                      onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                          setSubmitting(false);
                          signUp(values);
                        }, 500);
                      }}
                      validationSchema={Yup.object().shape({
                        firstName: Yup.string().trim().required(t('Required')),
                        lastName: Yup.string().trim().required(t('Required')),
                        email: Yup.string().email(t("Email must be a valid email")).required(t("Required")),
                        password: Yup.string()
                          .required("No password provided.")
                          .min(
                            8,
                            t("Password is too short - should be 8 chars minimum")
                          )
                          .matches(
                            /(?=.*[0-9])/,
                            "Password must contain a number."
                          ),
                      })}

                    >
                      {(props) => {
                        const {
                          values,
                          touched,
                          errors,
                          isSubmitting,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                        } = props;
                        return (
                          <form onSubmit={handleSubmit}>
                            <MDBInput
                              label={t("First Name")}
                              group
                              name="firstName"
                              validate
                              error="wrong"
                              success="right"
                              value={values.firstName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.firstName && touched.firstName && (
                              <div className="input-feedback">{errors.firstName}</div>
                            )}
                            <MDBInput
                              label={t("Last Name")}
                              group
                              type="text"
                              name="lastName"
                              validate
                              error="wrong"
                              success="right"
                              value={values.lastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.lastName && touched.lastName && (
                              <div className="input-feedback">{errors.lastName}</div>
                            )}
                            <MDBInput
                              label={t("e-mail address")}
                              group
                              type="email"
                              name="email"
                              validate
                              error="wrong"
                              success="right"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              autoComplete='off'
                            />
                            {errors.email && touched.email && (
                              <div className="input-feedback">{errors.email}</div>
                            )}
                            <MDBInput
                              label={t("password")}
                              name="password"
                              group
                              type="password"
                              validate
                              containerClass="mb-0"
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.password && touched.password && (
                              <div className="input-feedback">
                                {errors.password}
                              </div>
                            )}
                            <div className="text-center mb-3">
                              <MDBBtn
                                rounded
                                className="btn-block z-depth-1a sign-in-button btn wwt-btn w-100 btn-pink"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                Sign Up
                              </MDBBtn>
                            </div>
                            <div className='d-flex justify-content-center'>
                              <div>
                                {t("Already have an account")} <span className='blue-text  cursor-pointer' onClick={toggleSignUpForm}>{t("Sign in")}</span>
                              </div>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                    :
                    <Formik
                      key='signInForm'
                      initialValues={{ email: "", password: "" }}
                      onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                          setSubmitting(false);
                          signIn(values);
                        }, 500);
                      }}
                      validationSchema={Yup.object().shape({
                        email: Yup.string().email(t("Email must be a valid email")).required(t("Required")),
                        password: Yup.string()
                          .required("No password provided.")
                          .min(
                            8,
                            t("Password is too short - should be 8 chars minimum")
                          )
                          .matches(
                            /(?=.*[0-9])/,
                            "Password must contain a number."
                          ),
                      })}
                    >
                      {(props) => {
                        const {
                          values,
                          touched,
                          errors,
                          isSubmitting,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                        } = props;
                        return (
                          <form onSubmit={handleSubmit}>
                            <MDBInput
                              label={t("e-mail address")}
                              group
                              type="email"
                              name="email"
                              validate
                              error="wrong"
                              success="right"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.email && touched.email && (
                              <div className="input-feedback">{errors.email}</div>
                            )}
                            <MDBInput
                              label={t("password")}
                              name="password"
                              group
                              type="password"
                              validate
                              containerClass="mb-0"
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.password && touched.password && (
                              <div className="input-feedback">
                                {errors.password}
                              </div>
                            )}
                            <div className="font-small blue-text d-flex justify-content-end pb-3 cursor-pointer" onClick={toggleForgotPasswordModal}>
                              {/* Forgot
                          <a href="#!" className="blue-text ml-1">
                                Password?
                          </a> */}
                              {t("Forgot Password?")}
                            </div>
                            <div className="text-center mb-3">
                              <MDBBtn
                                rounded
                                className="btn-block z-depth-1a sign-in-button btn wwt-btn w-100 wwt-btn-blueli"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                {t("Sign in")}
                              </MDBBtn>
                            </div>
                            {
                              signUpEnabled &&
                              <div className='d-flex justify-content-center'>
                                <div>
                                  <span className='blue-text  cursor-pointer' onClick={toggleSignUpForm}>{t("Don’t have an account? Sign up")}</span>
                                </div>
                              </div>
                            }
                          </form>
                        );
                      }}
                    </Formik>
                  }
                  <div className="text-left">
                    <div className="login-modal-explanation modal-bottom">
                      {t("By signing up, you accept our ")}
                      <a href="#" className="legal-modal-link"><u><LegalModal title={t("privacy policy")} /></u></a>
                    </div>
                  </div>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </Loader>
      </MDBModal>

      <MDBModal isOpen={forgotPasswordModal} toggle={toggleForgotPasswordModal} className="login-modal">
        <Loader loading={loading}>
          <MDBRow>
            <MDBCol>
              <MDBCard>
                <MDBCardBody>
                  <div className="text-left">
                    <div className="login-title">
                      {t("Reset your password")}
                    </div>
                    <div className="login-modal-explanation">
                      {t("Please enter your email address. We will send you an email to reset your password.")}
                    </div>
                  </div>
                  <Formik
                    key='signInForm' initialValues={{ email: "" }}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        setSubmitting(false);
                        sendResetPasswordEmail(values);
                      }, 500);
                    }}
                    validationSchema={Yup.object().shape({
                      email: Yup.string().email().required(t("Required"))
                    })}
                  >
                    {(props) => {
                      const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;
                      return (
                        <form onSubmit={handleSubmit}>
                          <MDBInput label={t("Email Address")} group type="email" name="email" validate error="wrong" success="right" value={values.email}
                            onChange={handleChange} onBlur={handleBlur} />
                          {errors.email && touched.email && (
                            <div className="input-feedback">{errors.email}</div>
                          )}
                          <div className="text-center mb-3">
                            <MDBBtn
                              rounded className="btn-block z-depth-1a sign-in-button btn wwt-btn w-100 btn-primary"
                              type="submit" disabled={isSubmitting}>
                              {t("Send email")}
                            </MDBBtn>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>

                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </Loader>
      </MDBModal>
    </MDBContainer>
  );
}

export default withRouter(LoginModal);

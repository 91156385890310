import React from 'react'
import { useTranslation } from 'react-i18next'
import lady_picture from '../../../assets/philo.svg'
import '../Results.css'

const TopPage = () => {

  const { t } = useTranslation();

  return (
    <div className="results-rectangle-1">
        <div className="results-rectangle-1-text">
          {t("Congrats!")} <br /><br />{t("You validated the first step.")} <br /><br />
          {t("The Work Well Together ")}
          <b>{t("experience continues")}</b>!
          <br />{t("- Instantly get an extract of ")}
          <b>{t("your relational profile")}</b>
          <br />{t("- Get a complete relational profile from the person who sent you the invite link ")}
          <br />{t("- Find potential relational matches in your professional network via your contact ")}
        </div>
        <div className="results-rectangle-1-picture">
            <img src={lady_picture} alt="lady" className="results-lady-picture" />
        </div>
    </div>
  )
}

export default TopPage
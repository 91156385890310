import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import up_icon from '../../../../assets/up.png'
import down_icon from '../../../../assets/down.png'
import { getLanguage } from 'utils/utils'
import './Accordion.css'

function Accordion(props) {

  const [expand, setExpand] = useState(props.page === 1 ? false : true)
  const [description, setDescription] = useState('')
  const { t } = useTranslation();

  const handleExpand = () => {
    setExpand(!expand)
  }

  const lang = getLanguage();

  useEffect(() => {
    switch(lang) {
      case 'en':
        setDescription(props.content.en)
        break
      case 'fr':
        setDescription(props.content.fr)
        break
      case 'ru':
        setDescription(props.content.ru)
        break
      default:
        break
    }
  }, [props, lang])

  return (
    <div className="accordion">
      <div className="accordion-row-1">
        <div className="accordion-title">
          {props.page === 1? `${description.slice(0,30)}...` : t(props.title)}
        </div>
        <div className="accordion-icon-frame" onClick={handleExpand}>
          <img src={expand ? up_icon : down_icon} alt="accordion-icon" className="accordion-icon" />
        </div>
      </div>
      {
        expand && 
        <div className="accordion-content">
          {description}
      </div>
      }
      <hr />
    </div>
  )
}

export default Accordion
import React, { createRef, useEffect, useState } from 'react'
import { useScreenshot } from 'use-react-screenshot'
import axios from 'axios'
import Header from './SubPages/Header'
import TopPage from './SubPages/TopPage'
import FirstPage from './SubPages/FirstPage'
import SecondPage from './SubPages/SecondPage'
import ThirdPage from './SubPages/ThirdPage'
import InviteRec from './SubPages/Relations/InviteRec'
import CallToAction from './SubPages/Relations/CallToAction'
import CertificateRec from './SubPages/Relations/CertificateRec'
import ShareRec from './SubPages/Relations/ShareRec'
import BottomMenu from '../BottomMenu/BottomMenu'
import server from '@config'
import ReactGA from 'react-ga';
import toast from 'react-hot-toast';

import './Results.css'
import DownloadRec from './SubPages/Relations/DownloadRec'

function Results() {

  const [profile, setProfile] = useState()
  const [graphA, setGraphA] = useState()
  const [graphB, setGraphB] = useState()
  const [softSkills, setSoftSkills] = useState([])
  const [wellbeing_expression, setWellbeingExpression] = useState()
  const [relational_communication, setRelationalCommunication] = useState()
  const [approach_to_situations, setApproachToSituations] = useState()
  const [vision_of_the_world, setVisionOfTheWorld] = useState()
  const [top_personal_development, setTopPersonalDevelopment] = useState()
  const [render, setRender] = useState(false)
  const [image, takeScreenshot] = useScreenshot()
  const [fileUrls, setFileUrls] = useState({});

  const ref = createRef(null)

  const getProfile = () => {
    let data = JSON.stringify({ "email": localStorage.getItem("email") });
    ReactGA.set({ userId: localStorage.getItem("referral") });

    let config = {
      method: 'post',
      url: server + '/result/profile/',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(res => {
        setProfile(res.data['result'])
        localStorage.setItem('profile', JSON.stringify(res.data['result']));
      })
      .catch(err => {
        console.log(err, 'get profile picture error')
        toast.error('Failed to retrieve the user profile.')
      })
  }

  useEffect(() => {
    getProfile();
    getIndividualResult();
  }, [])

  const getIndividualResult = () => {
    let data = { "email": localStorage.getItem('email') }
    let config = {
      method: 'post',
      url: server + '/result/individual_result/',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };


    axios(config)
      .then(res => {
        setTopPersonalDevelopment({
          'en': res.data['top_personal_development_en'],
          'fr': res.data['top_personal_development_fr'],
          'ru': res.data['top_personal_development_ru']
        })
        setGraphA({
          'W': res.data['w_1'],
          'X': res.data['x_1'],
          'Y': res.data['y_1'],
          'Z': res.data['z_1']
        })
        setGraphB({
          'W': res.data['w_2'],
          'X': res.data['x_2'],
          'Y': res.data['y_2'],
          'Z': res.data['z_2']
        })
        if (res.data.soft_skills) {
          setSoftSkills(res.data.soft_skills)
        } else {
          window.location.href = '/choice';
        }
        setWellbeingExpression({
          'en': res.data['wellbeing_expression_en'],
          'fr': res.data['wellbeing_expression_fr'],
          'ru': res.data['wellbeing_expression_ru']
        })
        setRelationalCommunication({
          'en': res.data['relational_communication_en'],
          'fr': res.data['relational_communication_fr'],
          'ru': res.data['relational_communication_ru']
        })
        setApproachToSituations({
          'en': res.data['approach_to_situations_en'],
          'fr': res.data['approach_to_situations_fr'],
          'ru': res.data['approach_to_situations_ru']
        })
        setVisionOfTheWorld({
          'en': res.data['vision_of_the_world_en'],
          'fr': res.data['vision_of_the_world_fr'],
          'ru': res.data['vision_of_the_world_ru'],
        })
        setFileUrls({
          'en': {
            complete_profile_file_pdf: res.data['complete_profile_file_pdf_en'],
            relational_cv_file_pdf: res.data['relational_cv_file_pdf_en'],
            relational_cv_file_docx: res.data['relational_cv_file_docx_en']
          },
          'fr': {
            complete_profile_file_pdf: res.data['complete_profile_file_pdf_fr'],
            relational_cv_file_pdf: res.data['relational_cv_file_pdf_fr'],
            relational_cv_file_docx: res.data['relational_cv_file_docx_fr']
          },
          'ru': {
            complete_profile_file_pdf: res.data['complete_profile_file_pdf_ru'],
            relational_cv_file_pdf: res.data['relational_cv_file_pdf_ru'],
            relational_cv_file_docx: res.data['relational_cv_file_docx_ru']
          }
        })
        setRender(true)
      })
      .catch(err => {
        console.log(err)
        toast.error("Failed to retrieve individual results")
      })
  }

  return (
    <div className='results-page'>
      <Header profile={profile} />
      { render &&
        <div className="results-body wwt-container">
          <TopPage />
          <div ref={ref}>
            <FirstPage graphA={graphA} graphB={graphB} />
            <SecondPage render_data={[wellbeing_expression, relational_communication, approach_to_situations, vision_of_the_world]} />
            {/* <ThirdPage softSkills={softSkills} top_personal_development={top_personal_development} /> */}
          </div>
          {/* {profile &&
            <DownloadRec profile={profile} getProfile={getProfile} fileUrls={fileUrls} getIndividualResult={getIndividualResult} />}
          <InviteRec />
          {profile &&
            <ShareRec profile={profile} graphA={graphA} graphB={graphB} softSkills={softSkills} />
          }
          <CertificateRec /> */}
          <CallToAction />
        </div>
      }
      <BottomMenu id='results' />
    </div>
  )
}

export default Results
import React from 'react'
import { useTranslation } from 'react-i18next'
import Graph from './Graph/Graph'
import HelpModal from './HelpModal'
import '../Results.css'

function FirstPage(props) {

  const { t } = useTranslation();
  return (
    <div id='resultsFirstPage' className="results-first-page">
      <div className="results-rectangle-2">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="results-diagram-title">{t("Your relational profile")}</div>
          {/* <HelpModal /> */}
        </div>
        {/* <Graph graphA={props.graphA} graphB={props.graphB} /> */}
        {/* <div style={{ marginTop: '48px', padding: "0 30px", textAlign: "left" }}>
          <div className="graph-explain-content">{t("Your behaviour and relations")}</div>
          <div style={{ display: "flex" }}>
            <div className="graph-oval-1" />
            <div className="graph-oval-content">{t("On a day to day basis")}</div>
          </div>
          <div style={{ display: "flex" }}>
            <div className="graph-oval-2" />
            <div className="graph-oval-content">{t("Under stress")}/{t("tension")}</div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default FirstPage